<template>
    <div id="bind">
        <common-header></common-header>
        <div class="bind-wrap">
            <div>
                <h1>绑定手机号</h1>
                <span class="s-title">轻重时间管理工具</span>
                <div class="bind-phone">
                    <div class="userName">
                        <div class="font"><i class="iconfont iconuser"></i></div>
                        <input type="text" placeholder="请输入11位手机号码
" v-model="phone" maxlength="11" />
                    </div>
                    <div class="password">
                        <div class="font"><i class="iconfont iconVerificationCode1"></i></div>
                        <input
                                type="text"
                                placeholder="验证码"
                                v-model="code"
                                maxlength="10"
                                @keydown.enter="submitForm"
                        />
                        <el-button
                                type="button"
                                @click="sendcode"
                                :disabled="disabled"
                                v-if="!disabled"
                                class="send"
                        >获取验证码</el-button>
                        <el-button
                                type="button"
                                :disabled="disabled"
                                v-if="disabled"
                                class="send ncolor"
                        >{{btntxt}}</el-button>
                    </div>
                </div>
<!--                提交绑定-->
                <div @click="submitForm" class="bind-submit">登录</div>
<!--                <div class="back"><a @click="back">使用账号登录</a></div>-->
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import CommonHeader from "@/components/CommonHeader.vue";
    export default {
        name: "Bind",
        components: {
            CommonHeader
        },
        data() {
            return {
                phone: "",
                code: "",
                disabled: false,
                btntxt: ""
            };
        },
        methods: {
            back() {
                this.$router.back()
            },
            submitForm() {
                let code = localStorage.code
                this.$http({
                    url: this.api.wx_login,
                    data: {
                        wx_login_code: code,
                        phone: this.phone,
                        code: this.code
                    },
                })
                    .then((res) => {
                        console.log(res)
                        if (res.code === 0) {
                            localStorage.j_token = res.data.j_token;
                            localStorage.j_username = res.data.username;

                            this.$message.success({
                                message:
                                    res.msg + "，登录时间 " + res.data.login_at,
                                center: true,
                            })
                            if (!localStorage.userActive) {
                                localStorage.userActive = 1;
                                this.$router.push("/explain");
                            } else if (localStorage.userActive) {
                                this.$router.push("/index");
                            }
                        } else {
                            this.$message.warning({
                                message: res.msg,
                                center: true,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            // 发送验证码
            sendcode() {
                if (!this.phone) return
                // 发送成功
                this.$http({
                    url: this.api.get_phone_code,
                    data: {
                        phone: this.phone,
                    },
                })
                    .then((res) => {
                        console.log("获取验证码", res);
                        if (res.code === 1) {
                            this.$message.warning({
                                message: res.msg,
                                center: true,
                            });
                        } else if (res.code === 0) {
                            this.textedTime = 60;
                            this.disabled = true;
                            this.timer();
                            this.$message.success({
                                message: res.msg,
                                center: true,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            // 60S倒计时
            timer() {
                console.log(1)
                if (this.textedTime > 0) {
                    this.textedTime--;
                    this.btntxt = this.textedTime + "s后重新获取";
                    setTimeout(this.timer, 1000);
                } else {
                    this.textedTime = 0;
                    this.btntxt = "获取验证码";
                    this.disabled = false;
                }
            },
        },
    };
</script>

<style lang="less">
    #bind {
        min-width: 1200px;
        height: 100vh;
        background: #f9f9f9;
        .el-button {
            padding: 0 0;
        }
        .bind-wrap {
            width: 1200px;
            height: 680px;
            min-width: 1200px;
            margin: 77px auto 0 auto;
            background: #fff;
            display: flex;
            justify-content: center;

            h1 {
                font-size: 34px;
                color: #333;
                text-align: center;
                padding-top: 140px;
                font-weight: normal;
            }
            .s-title {
                display: block;
                font-size: 12px;
                color: #5D5D5D;
                text-align: center;
                font-weight: normal;
                line-height: 22px;
            }
            .bind-phone {
                width: 336px;
                margin: 100px auto 0 auto;

                .userName, .password {
                    position: relative;
                    .font {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                input::-webkit-input-placeholder,
                textarea::-webkit-input-placeholder {
                    color: #c1c1c1;
                }

                input:-moz-placeholder,
                textarea:-moz-placeholder {
                    color: #c1c1c1;
                }

                input::-moz-placeholder,
                textarea::-moz-placeholder {
                    color: #c1c1c1;
                }

                input:-ms-input-placeholder,
                textarea:-ms-input-placeholder {
                    color: #c1c1c1;
                }

                input {
                    font-size: 16px;
                    width: 100%;
                    padding: 10px 10px 10px 32px;
                    background: none;
                    outline: none;
                    border: none;
                    border-bottom: 1px solid #d3d3d3;
                    box-sizing: border-box;
                }

                .iconfont {
                    color: #5d5d5d;
                    font-size: 22px;
                }

                .password {
                    margin-top: 10px;
                    position: relative;
                    .send {
                        line-height: 0;
                        width: 120px;
                        height: 30px;
                        background: #70ccff;
                        font-size: 14px;
                        color: #fffefe;
                        text-align: center;
                        position: relative;
                        left: 245px;
                        bottom: 36px;
                        border: none;
                        display: flex !important;
                        align-items: center !important;
                        justify-content: center !important;
                        span {
                            display: block;
                            /*width: 120px;*/
                            height: 30px;
                            line-height: 30px;
                        }
                    }
                    .ncolor {
                        background: #d3d3d3;
                    }
                    .el-button--button {
                        display: inline-block;
                        width: initial;
                        font-size: 16px;
                        padding: 10px 5px;
                        height: 16px;
                        position: absolute;
                        bottom: 10px;
                        right: 0;
                        left: initial !important;
                        background: none;
                        color: #999999;
                        border-left: 2px solid;
                        border-radius: 0;
                    }
                }
            }
        }
        .bind-submit {
            width: 100%;
            height: 42px;
            font-size: 16px;
            text-align: center;
            line-height: 42px;
            color: #fff;
            border-radius: 4px;
            background: #70CCFF;
            margin-top: 48px;
            cursor: pointer;
        }
        .back {
            text-align: center;
            margin-top: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            a {
                cursor: pointer;
                color: #70CCFF;
                padding: 5px;
            }
        }
    }
</style>
